exports.onInitialClientRender = () => {
  
 page_notes();
}

function page_notes(){
    
  const footNotes = document.querySelectorAll('.footnote-ref');
    
  //
  // fires only if a page has footnotes
  //
  if (typeof footNotes !== 'undefined' ){   

   // let aside_current;
       // main = document.getElementsByTagName('main')[0];
      
    
    
    function pagenoteClickListener(event){

      
      //
      // prevent scrolling to the footnotes at the bottom of the page
      //
      event.preventDefault();
      
        let// tooltip = document.createElement('span'),
           // noteOffset = offset(event.target),
          //  left = noteOffset.left,
          //  windowWidth = window.innerWidth,
           // right = windowWidth - left,
            sidebar = document.getElementById('sidebar'),
            hide_sidebar = document.getElementById('hideSidebar'),
            footNoteId,
            footNote;

            // console.log('left ' +left  + ' right ' + right);
            // console.log(sidebar);

              

              
            // aside_current = this.nextSibling; 
              
            // tooltip.setAttribute('class', 'tooltip');
              
              //
              // get the footnote content and re-use it
              //
        footNoteId = this.getAttribute('href').replace('#','');
        footNote = document.getElementById(footNoteId).children[0].innerHTML;
        
        sidebar.getElementsByTagName('p')[0].innerHTML = footNote;

        //
        // toggle sidebar and "close sidebar" element that is currently stretched to full screen 
        //
        if(sidebar.classList.contains('is-active') !== true){
          sidebar.classList.add('is-active');
         // hide_sidebar.classList.add('is-active');
          hide_sidebar.addEventListener('click', close_sidebar);
        } else{

        }

        function close_sidebar(){
           sidebar.classList.remove('is-active');
           hide_sidebar.classList.remove('is-active');
         //  hide_sidebar.removeEventListener('click', close_sidebar);
        }
      
        //if(aside_current.querySelector('p') == null){

          //
          // append textpage note to sidebar 
          //
         
           //aside_current.appendChild(document.createElement('p'));
           // aside_current.children[0].innerHTML = footNote;
           //aside_current.appendChild(tooltip);
           
        //}
       // aside_current.classList.add('is-active');
/*
        if(left < 173 ){
          
          let difference = (173 - left);

          aside_current.style.cssText = 'margin-left:' + difference + 'px;';
          tooltip.style.cssText = 'left:' + (146-difference)  + 'px;';
        
        }else if (right < 173){
          
          let difference = (173 - right) + 'px;';

          aside_current.style.cssText = 'margin-left:-' + difference + 'px;';
          tooltip.style.cssText = 'left:' + (difference +146)+'px;';
        }
        */
       // aside_current.setAttribute('id','active-pagenote');
       //main.addEventListener('click',outsideClickListener, true);
       // removeClickListener(pagenoteClickListener);


      }

  /*

    function offset(el) {
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
    
     /* function outsideClickListener(event){
        
        let active_note = document.getElementById('active-pagenote');
        
        if(typeof active_note != 'undefined'){
          //closePageNote(aside_current);
        }
        
        if((event.target.classList.contains('pagenote') !== true)
        && (event.target.closest('aside') == null)){
          closePageNote(aside_current);
          main.removeEventListener('click', outsideClickListener, true);
        } 
         
      }
      */
/*
    function closePageNote(pagenote){
      pagenote.classList.remove('is-active');

    }
    */
    footNotes.forEach(function(element){


     // let currentNoteWrapper = element.closest('sup'),
      //    aside = document.createElement('aside');
      //    aside.setAttribute('class', 'pagenote');

     // currentNoteWrapper.appendChild(aside);

      element.addEventListener('click', pagenoteClickListener);

    });    
    
  }
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  page_notes();
}

/*


  function hideOnClickOutside(element) {
      
    const outsideClickListener = event => {
      
        if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
         // element.style.display = 'none';
          
          

          removeClickListener();
        }
    }

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);

    const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ) 
    // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js 
  }

*/


